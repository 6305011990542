import React from "react";
import { ThemeProvider } from "styled-components";
import { Modal } from "@redq/reuse-modal";
import { theme } from "common/theme/appModern";
import { ResetCSS } from "common/assets/css/style";
import Sticky from "react-stickynode";
import Navbar from "containers/AppModern/Navbar";
import Testimonial from "containers/AppModern/Testimonial";
import Banner from "containers/AppModern/Vission";
import VideoSection from "containers/AppModern/VideoSection";
import Footer from "containers/AppModern/Footer";
import GlobalStyle, {
  AppWrapper,
  ContentWrapper,
} from "containers/AppModern/appModern.style";
import "@redq/reuse-modal/es/index.css";

import Seo from "components/seo";
import TeamMember from "../containers/AppModern/TeamMember";

function ouroffice() {
  return (
    <div>
      <ThemeProvider theme={theme}>
        <>
          <Seo title="Comrex Pvt Ltd:" />
          <Modal />
          <ResetCSS />
          <GlobalStyle />

          <AppWrapper>
            <Sticky top={0} innerZ={9999} activeClass="sticky-active">
              <Navbar />
            </Sticky>
            <ContentWrapper>
              <Banner />
              <Testimonial />

              <VideoSection />
            </ContentWrapper>

            <Footer />
          </AppWrapper>
        </>
      </ThemeProvider>
    </div>
  );
}

export default ouroffice;
