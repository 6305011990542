/* eslint-disable */
import React from "react";
import Fade from "react-reveal/Fade";
import { useStaticQuery, graphql } from "gatsby";
import { Icon } from "react-icons-kit";
import { playCircle } from "react-icons-kit/fa/playCircle";
import { openModal, closeModal } from "@redq/reuse-modal";
import Text from "common/components/Text";
import Image from "common/components/Image";
import Button from "common/components/Button";
import Heading from "common/components/Heading";
import Rating from "common/components/Rating";
import Container from "common/components/UI/Container";
import GatsbyImage from "common/components/GatsbyImage";
import BannerWrapper, {
  BannerContent,
  RatingInfo,
  BannerImage,
  ButtonGroup,
  VideoGroup,
  VideoWrapper,
  CustomerWrapper,
  ImageWrapper,
} from "./banner.style";

import microsoft from "common/assets/image/appModern/envato-icon.png";
import videoBanner1 from "common/assets/image/appModern/video-1.png";
import videoBanner2 from "common/assets/image/appModern/video-2.png";
import circleBorder from "common/assets/image/appModern/shape.svg";
import mockup from "common/assets/image/appModern/banner22.png";
import iso from "common/assets/image/appModern/iso.png";

// close button for modal
const CloseModalButton = () => (
  <Button
    className="modalCloseBtn"
    variant="fab"
    onClick={() => closeModal()}
    icon={<i className="flaticon-plus-symbol" />}
  />
);

const ModalContent = () => (
  <VideoWrapper>
    <iframe
      title="Video"
      src="https://www.youtube.com/embed/8ME-QAlW6Ww"
      frameBorder="0"
    />
  </VideoWrapper>
);

const Banner = () => {
  const data = useStaticQuery(graphql`
    query {
      appModernJson {
        client {
          id
          title
          image {
            publicURL
          }
        }
      }
    }
  `);
  const { client } = data.appModernJson;
  // modal handler
  const handleVideoModal = () => {
    openModal({
      config: {
        className: "video-modal",
        disableDragging: true,
        default: {
          width: "auto",
          height: "auto",
          x: 0,
          y: 0,
        },
      },
      component: ModalContent,
      componentProps: {},
      closeComponent: CloseModalButton,
      closeOnClickOutside: true,
    });
  };
  return (
    <BannerWrapper id="home">
      <Container>
        <BannerContent>
          {/* <Fade down delay={100}>
          <div style={{display: "flex", alignItems:"end"}}>
          <Image src={iso} alt="Banner" style={{width: "100px"}}/>
            <Text
              
              content="We are ISO certified."
            />
          </div>
           
          </Fade> */}
          <Fade up delay={100}>
            <Heading as="h1" content="Our Mission" />
          </Fade>
          <Fade up delay={200}>
            <Text
              content="We empower businesses worldwide by offering unparalleled employer of record services, top-tier
software engineering solutions, and strategic outsaffing expertise. Our commitment is to streamline
operations, foster innovation, and enable our clients to thrive in an ever-evolving global
marketplace."
            />
          </Fade>
        </BannerContent>
        {/* <BannerImage>
          <Fade up delay={100}>
            <Image
              src={mockup}
              alt="Banner"
              style={{
                width: "600px",
                borderRadius: "70px 70px 70px 70px",
                border: "8px solid palevioletred",
              }}
            />
          </Fade>
        </BannerImage> */}
      </Container>

      {/* <img
        className="bannerBottomShape"
        src={circleBorder}
        alt="Bottom Circle"
      /> */}
    </BannerWrapper>
  );
};

export default Banner;
